import { masterChefV3Addresses } from '@pancakeswap/farms'
import { DEPLOYER_ADDRESSES } from '@pancakeswap/v3-sdk'
import { V3_QUOTER_ADDRESSES } from '@pancakeswap/smart-router/evm'

export default {
  masterChef: {
    97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    56: '0x5F33006E82C35813cc231383A6e07a24Cc65516f',
    16507: '0x9140B00Fd81871b7506FB78D348490BA1B61bD85',
  },
  masterChefV3: masterChefV3Addresses,
  masterChefV1: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0xC6443CA78f12764c2d98A1ccCEF9961194bD26D3',
    16507: '0xa6cDE9234787165c0a6b2F8Fbe6657f823b201f8',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  },
  multiCall: {
    1: '0xcA11bde05977b3631167028862bE2a173976CA11',
    4: '0xcA11bde05977b3631167028862bE2a173976CA11',
    5: '0xcA11bde05977b3631167028862bE2a173976CA11',
    56: '0xcA11bde05977b3631167028862bE2a173976CA11',
    97: '0xcA11bde05977b3631167028862bE2a173976CA11',
    280: '0xF9cda624FBC7e059355ce98a31693d299FACd963',
    324: '0xF9cda624FBC7e059355ce98a31693d299FACd963',
    16507: '0x90a2377F233E3461BACa6080d4837837d8762927',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    16507: '0x4149327bD4d8D4342E1eA3c89C29eeC955Eba277',
  },
  pancakeBunnies: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
    16507: '0x1f776daB2906a8eeaC301542e9dbbe68d4c62961',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    16507: '0x05A7bD95522A2Ff691820829A2CbCeb3968b0e64',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '0x',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    16507: '0xF6CcEbd019f275c0Fb272E1f38E949E856A8C305',
  },
  tradingCompetitionEaster: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    16507: '0xc7603900b684A705AA891CF8FCC6fa48528630DC',
  },
  tradingCompetitionFanToken: {
    56: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    97: '0x',
  },
  tradingCompetitionMobox: {
    56: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    97: '0x',
  },
  tradingCompetitionMoD: {
    56: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    97: '0x',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    16507: '0x9DD3D84Ef94344A2cc72B1f028f8CC81B8e1A2cd',
  },
  // CakePool
  cakeVault: {
    56: '0xE356E3592b966ce55Fed564F49cc9a07997A9230',
    97: '0x1088Fb24053F03802F673b84d16AE1A7023E400b',
    16507: '0x7b6488d38429A1A0eC37aC7550b27e4FCded7220',
  },
  cakeFlexibleSideVault: {
    56: '0xF81404ac28E688a227A6160958f9D065B75aFdbC',
    97: '0x1088Fb24053F03802F673b84d16AE1A7023E400b',
    16507: '0x020eDBd720EE1c68551cb3bc24001D2D3eaBCd37',
  },
  predictionsBNB: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '0x',
  },
  predictionsCAKE: {
    56: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    97: '0x',
  },
  chainlinkOracleBNB: {
    56: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    97: '0x',
  },
  chainlinkOracleCAKE: {
    56: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    97: '0x',
  },
  predictionsV1: {
    56: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    97: '0x',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '0x',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '0x',
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  bunnySpecialXmas: {
    56: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    97: '0x',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  nftMarket: {
    56: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    16507: '0x3E79F163C15f9d7590a7bBE489150815dCfF3fC6',
  },
  nftSale: {
    56: '0x8C706a34c94CCdE7b944101e60Db5D8CDdB1380D',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    16507: '0x8C706a34c94CCdE7b944101e60Db5D8CDdB1380D',
  },
  pancakeSquad: {
    56: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    97: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    16507: '0xeEB9A607652F1Ba76Ab96842cE9dd810203FC5C2',
  },
  potteryDraw: {
    56: '0x01871991587d5671f3A2d4E2BcDC22F4E026396e',
    97: '0xDB9D365b50E62fce747A90515D2bd1254A16EbB9',
  },
  zap: {
    56: '0xD4c4a7C55c9f7B3c48bafb6E8643Ba79F42418dF',
    97: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
  },
  stableSwapNativeHelper: {
    56: '0x52E5D1e24A4308ef1A221C949cb2F7cbbAFEE090',
    97: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
  },
  iCake: {
    56: '0x310c3F7717043BD57f06fcC8c5AdC2eEf419436B',
    97: '0x',
    16507: '0xe7E67b1678dDC1c467BF88173aCe5648F2F228ed',
  },
  // FarmBoosterV2
  bCakeFarmBooster: {
    56: '0x27020fc579AAB8F0A0A44a9D2179f7D889aCe495',
    97: '0x',
    16507: '0xD0c93eB920cB173B1Cc8E44987311136B7db1f0b',
  },
  // FarmBoosterProxyFactory
  bCakeFarmBoosterProxyFactory: {
    56: '0xb55cDb744074aaaEE8De557787BF4f7D303EDb24',
    97: '0x',
    16507: '0x1457d39A873dBe5EE33044c7C1A9f2C96cB43EeE',
  },
  // FarmBoosterV3
  bCakeFarmBoosterV3: {
    56: '0xaf03eC373aE4c729C576c40e7bd31d7546B3902C',
    97: '0x56666300A1E25624489b661f3C6c456c159a109a',
    16507: '0x7c512a2124d2a2BD6B8397d88326BfaB29A508a4',
  },
  nonBscVault: {
    1: '0x2e71B2688019ebdFDdE5A45e6921aaebb15b25fb',
    5: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
  },
  crossFarmingSender: {
    1: '0x8EA90Ef07f37c77137453C7A1B72B7886d51eCFb',
    5: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
  },
  crossFarmingReceiver: {
    56: '0x0726a8C8206b9eC0AfB788df5adb36a8AEDB13c2',
    97: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
  },
  mmLinkedPool: {
    1: '0x9Ca2A439810524250E543BA8fB6E88578aF242BC',
    5: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    56: '0xfEACb05b373f1A08E68235bA7FC92636b92ced01',
  },
  tradingReward: {
    1: '0x',
    56: '0xa842a4AD40FEbbd034fbeF25C7a880464a90e695',
    97: '0x',
  },
  // NonfungiblePositionManager
  nftPositionManager: {
    1: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    5: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    56: '0x1Cdae91AACda14Ef635cCA8cD013A07e24861459',
    97: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    280: '0xF84697CfE7c88F846e4ba5dAe14A6A8f67deF5c2',
    324: '0xa815e2eD7f7d5B0c49fda367F249232a1B9D2883',
    1101: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1442: '0x1f489dd5B559E976AE74303F939Cfd0aF1b62C2B',
    42161: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    59140: '0xacFa791C833120c769Fd3066c940B7D30Cd8BC73',
    421613: '0xb10120961f7504766976A89E29802Fa00553da5b',
    16507: '0xEa606DE7f44AB84cb54acd9534a3C828Ebd54AE3',
  },
  v3PoolDeployer: DEPLOYER_ADDRESSES,
  v3Migrator: {
    1: '0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2',
    5: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    56: '0xe4F2e127453c7442F3150EC44882B5F4030610d0',
    97: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    280: '0x7627931617A60Fe58EDBf4881ac166E1eDe2379e',
    324: '0x556A72A7A3bB3bbd293D923e59b6B56898fB405D',
    1101: '0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2',
    1442: '0x4A3902773F947ce028969db670E568fFC9524E8C',
    42161: '0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2',
    59140: '0x3652Fc6EDcbD76161b8554388867d3dAb65eCA93',
    421613: '0xCcf770BdBD8ccC57a7a7ABff53825fD895a06238',
    16507: '0xBcebe7B834df658B9F94090D2867F66aF160cc15',
  },
  quoter: V3_QUOTER_ADDRESSES,
  v3Airdrop: {
    1: '0x',
    56: '0xe934d2C5bE5db0295A4de3177762A9E8c74Ae4f4',
    97: '0x',
  },
  affiliateProgram: {
    1: '0x',
    56: '0x92C73D90F709DFf7e5E7307e8F2EE20e39396b12',
    97: '0x1B8a475B5E5De05fB3Ac2D9ec3C2809fBF24e51c',
  },
  tradingRewardTopTrades: {
    1: '0x',
    56: '0x41920b6A17CB73D1B60f4F41D82c35eD0a46fD71',
    97: '0x',
  },
  vCake: {
    1: '0x',
    56: '0xa3b8321173Cf3DdF37Ce3e7548203Fc25d86402F',
    97: '0x5DD37E97716A8b358BCbc731516F36FFff978454',
  },
  revenueSharingPool: {
    1: '0x',
    56: '0xCD5d1935e9bfa4905f9f007C97aB1f1763dC1607',
    97: '0xd2d1DD41700d9132d3286e0FcD8D6E1D8E5052F5',
  },
} as const satisfies Record<string, Record<number, `0x${string}`>>
